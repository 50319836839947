

import galleryImg from '../../assets/images/gallery1.jpg';
import galleryImg2 from '../../assets/images/gallery2.jpg';
import galleryImg3 from '../../assets/images/gallery3.jpg';
import galleryImg4 from '../../assets/images/gallery4.jpg';
import galleryImg5 from '../../assets/images/gallery5.jpg';
import galleryImg6 from '../../assets/images/gallery6.jpg';
import galleryImg7 from '../../assets/images/gallery7.jpg';



const galleryImages = [
    galleryImg,
    galleryImg2,
    galleryImg3,
    galleryImg4,
    galleryImg5,
    galleryImg6,
    galleryImg7
];

export default galleryImages;